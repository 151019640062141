import React, { useState, useEffect } from 'react';
import { WithLabel } from './LabeledControl';
import { BarePropertyControlProps } from './PropertyControl'

export function BareTextControl({ value, readOnly, className = '', configuration, onChange }: BarePropertyControlProps) {
    const [internalValue, setInternalValue] = useState<string>(value || '');
    const validator = configuration?.validator ? `^${configuration.validator}$` : '';
    const hint = configuration?.validatorHint || 'Not valid';
    const regexp = new RegExp(validator);
    const isValid = regexp.test(internalValue);

    useEffect(() => { setInternalValue(value || '') }, [value]);
    const emitChange = (v: string) => onChange(v || null);

    return (
        <div className={className}>
            <input
                type='text'
                value={internalValue}
                disabled={readOnly}
                onChange={(e) => {
                    const newValue = e.target.value;
                    setInternalValue(newValue);
                    if (regexp.test(newValue)) emitChange(newValue);
                }}
                onKeyDown={e => ['Escape', 'Enter'].includes(e.key) && e.currentTarget.blur()}
            />
            <br />
            {isValid ? null : <span className='not_valid'>{hint}</span>}
        </div>);
}

export const TextControl = WithLabel(BareTextControl);
