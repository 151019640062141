import { tactin } from "../../utils/TactinGlobals";
import { OpenItemEvent } from "../events/TactinEvents";
import { ActionContextProvider } from "./ActionContext";

export function CreateNewItemSystemAction(configMap: any, contextProvider?: ActionContextProvider) {
    return async () => {
        const categoryID = configMap.categoryID || 0;
        const contextMap: { [key: string]: string } = configMap.contextMap || {};
        let openEvent;
        if (contextMap && contextProvider && contextProvider('item'))
            openEvent = new OpenItemEvent().byType(categoryID, '', contextMap, contextProvider);
        else
            openEvent = new OpenItemEvent().byType(categoryID);

        tactin()?.eventBus.notify(openEvent);
        return null;
    }
}
