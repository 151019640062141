import React, { useEffect, useRef, useState } from 'react';
import { TLocalEventBus } from '../../model/events/LocalEventBus';
import { GroupDataCache } from '../../model/list/cache/GroupCache';
import { rowParser } from '../../model/list/cache/RowParser';
import { ColumnFilter, FilterManager } from '../../model/list/ColumnFilter';
import { getRowCount, ListProperties } from '../../model/list/HelperTypes';
import { ActionContextProvider, ListActionContext } from '../../model/systemactions/ActionContext';
import { HandleErrors } from '../../utils/api/ApiErrorHandler';
import { api } from '../../utils/api/ApiProvider';
import { useSortingHandler } from './ListTable/ListTable';
import { ListViewer, useListSegmentSize } from './ListViewer';

type GroupListViewerProps = {
    dataProvider: string;
    wildcards: string[];
    filters: ColumnFilter[] | FilterManager;
    toolbarConfiguration: string[];
    decorate: boolean;
    blockDynamicGrouping?: boolean;
    toolbarUsagePlace: number;
    openOnDblClick: boolean;
    onSelect?: (id: number, showAs: string) => void;
    eventBus?: TLocalEventBus;
    showDescription?: boolean;
}

export default function GroupListViewer(props: GroupListViewerProps) {
    const segmentSize = useListSegmentSize();

    const [listProperties, setListProperties] = useState<ListProperties | null>(null);
    const [groups, setGroups] = useState<string[]>([]);
    const defaultGroups = useRef<string[]>();                  // undefined is the flag for the first request
    const { sorting, sortHandler } = useSortingHandler();
    const openedGroups = useRef<Map<string, number>>(new Map());
    const isComponentExist = useRef(true);

    const updateGroup = (group: string) => {
        if (groups.includes(group))
            setGroups(groups.filter(g => g !== group));
        else
            setGroups([...groups, group]);
        sortHandler('').updateSort('');
    }

    const [filters, _setFilters] = useState<ColumnFilter[]>([]);
    const filterThrottler = useRef(0);
    const setFilters = (fs: ColumnFilter[]) => {
        if (filterThrottler.current)
            clearTimeout(filterThrottler.current);
        filterThrottler.current = window.setTimeout(() => {
            filterThrottler.current = 0;
            _setFilters(fs);
        }, 300);
    }

    useEffect(() => () => {
            filterThrottler.current && clearTimeout(filterThrottler.current);
            isComponentExist.current = false;
    }, [])

    useEffect(() => {
        if (props.filters instanceof Array) {
            setFilters([...props.filters]);
            return () => { };
        } else {
            const filterManager = props.filters;
            if (!filters.length)
                getAvailableFilters(filterManager);
            return filterManager.addFilterHandler(setFilters);
        }
    }, [props.dataProvider, props.wildcards, props.filters]);

    const getAvailableFilters = (fm: FilterManager) => {
        api().List.getFiltersForDataProvider(props.dataProvider, false).then(v =>
            isComponentExist.current && fm.update(v)).catch(HandleErrors());
    }

    useEffect(() => {
        const filteredGroups = groups.filter(group => !defaultGroups.current?.includes(group));

        api().List.getProperties({
            dataProviderId: props.dataProvider,
            wildcards: props.wildcards,
            filters: filters,
            grouping: filteredGroups,
            skipColumns: false
        })
            .then(r => {
                if (isComponentExist.current) {
                    if (!defaultGroups.current) {
                        defaultGroups.current = r.columnGrouping;
                        if (r.columnGrouping.length > 0)
                            setGroups(r.columnGrouping);
                    }
                    setListProperties(r);
                }
            })
            .catch(HandleErrors());
    }, [props.wildcards, filters, groups, sorting.name, sorting.direction]);

    const cacheCreate = (listProperties: ListProperties) => {
        const cache = new GroupDataCache(0, groups, filters, segmentSize, openedGroups.current, rowParser(listProperties.columnNames));
        cache.dataProvider = props.dataProvider;
        cache.wildcards = props.wildcards;
        cache.setSort(sorting.name, sorting.direction);
        cache.setRowCount(getRowCount(listProperties));

        return cache;
    }

    const getContextProvider = (): ActionContextProvider =>
        (type) => {
            if (type === 'list')
                return new ListActionContext(
                    Number(props.dataProvider),
                    {
                        sortColumn: sorting.name,
                        sortDirection: sorting.direction,
                        wildcardValues: props.wildcards,
                        grouping: groups,
                        filters: filters
                    }
                );
            return null;
        }

    return <>
        {!listProperties ? mockList() :
            <ListViewer
                listProperties={listProperties}
                cacheCreator={cacheCreate}
                groupable={props.blockDynamicGrouping ? undefined : { updateGroup: updateGroup, defaultGroups: (defaultGroups.current || []), groups: groups }}
                sortable={sortHandler}
                saveOpenedGroups={(value) => openedGroups.current = value}
                decorate={props.decorate}
                footer={(rowCount, range) => {
                    const result: JSX.Element[] = [];
                    if (!groups.length)
                        result.push(<span key='rowCount'>{rowCount} pozycji{rowCount > 0 ? ` (${range.start + 1}-${range.start + range.length})` : ''}</span>);
                    return result;
                }}
                toolbarUsagePlace={props.toolbarUsagePlace}
                toolbarConfiguration={props.toolbarConfiguration}
                getContextProvider={getContextProvider}
                eventBus={props.eventBus}
                onSelect={props.onSelect}
                openOnDblClick={props.openOnDblClick}
                showDescription={props.showDescription || false} />
        }
    </>
}

const mockList = () =>
    <div className='item-list'>
        <div className='toolbar'></div>
        <div className='table'>
            <div className='tr header'></div>
            <div className='tbody'></div>
            <div className='tr footer'></div>
        </div>
    </div>
