import React, { useState } from 'react';
import { tactin } from '../../../utils/TactinGlobals';
import { GroupingHandler, SortingHandler } from './ListTable';
import TableCell, { TableCellProps } from './TableCell';
import TableRow, { TableRowProps } from './TableRow';

export function TablePadding({ height }: { height: number }) {
    if (height > 0)
        return <div style={{ display: 'inline-block', height: `${height}px`, color: 'transparent' }}>.</div>
    else
        return null;
}

type TableHeaderProps = {
    groupable?: GroupingHandler;
    sortable?: SortingHandler;
}

export function TableHeader(props: TableRowProps<TableHeaderProps>) {
    return <TableRow className='header' columns={props.columns} widths={props.widths}>
        {({ i, width, canGrow }) => <ColumnHeader key={`col${i}`} width={width} canGrow={canGrow} name={props.columns[i]} group={props.groupable} sort={props.sortable} />}
    </TableRow>
}

type ColumnHeaderProps = {
    name: string;
    group?: GroupingHandler;
    sort?: SortingHandler
}

export function ColumnHeader({ name, width, canGrow, group, sort }: TableCellProps<ColumnHeaderProps>) {
    const s = sort && sort(name);

    const [originalColumnName, setOriginalColumnName] = useState<String>(name);
    const [columnNameTranslation, setColumnNameTranslation] = useState<string>(tactin().configuration.translate(name.startsWith('"') ? name.slice(1, name.length - 1) : name));

    // Additional chek - when grouping is set the column names change (columns change positon)
    if (name !== originalColumnName) {
        setColumnNameTranslation(tactin().configuration.translate(name.startsWith('"') ? name.slice(1, name.length - 1) : name));
        setOriginalColumnName(name);
    }

    let rowClass = '';
    if (s && s.sortColumn === name)
        rowClass = s.sortDirection === 'ASC' ? ' sort-asc' : ' sort-desc';
    if (group && group.groups.includes(name))
        rowClass += ' grouped-by';

    return (<TableCell className={rowClass} width={width} canGrow={canGrow} onClick={() => s && s.updateSort(name)}>
        <span>{columnNameTranslation}</span>
        {group ?
            <button
                className='group-toggle'
                onClick={e => {
                    e.stopPropagation();
                    group.updateGroup(name);
                }}
            >{group.groups.includes(name) ? '>' : '<'}</button>
            : null
        }
    </TableCell>);
}

type TableFooterProps = {
    aggregations: string[];
}

export function TableFooter(props: TableRowProps<TableFooterProps>) {
    return <TableRow className='footer' columns={props.columns} widths={props.widths}>
        {({ i, width, canGrow }) =>
            <TableCell key={`col${i}`} width={width} canGrow={canGrow}>{props.aggregations[i] || ''}</TableCell>}
    </TableRow>
}
