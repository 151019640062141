import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import LocalEventBus from '../../model/events/LocalEventBus';
import { OpenItemEvent } from '../../model/events/TactinEvents';
import { setFilterValue, textColumnFilter } from '../../model/list/ColumnFilter';
import { isShowAsDeleted } from '../../utils/DeletedItem';
import { tactin } from '../../utils/TactinGlobals';
import UIFactory from '../core/UIFactory';
import { BarePropertyControlProps } from './PropertyControl';

type EntityComboBoxProps = BarePropertyControlProps & {
    noClearButton?: boolean;
    noActionButton?: boolean;
}

export function EntityComboBox(props: EntityComboBoxProps) {
    const localEventBusRef = useRef(new LocalEventBus());
    const [isPopupOpen, setPopupState] = useState(false);
    const [filter, setFilter] = useState<null | string>(null);
    const inputRef = useRef<HTMLInputElement>();
    const popupRef = useRef<HTMLDivElement>(null);
    const blockControl = useRef<HTMLDivElement>(null);
    const isAllowFiltering = useRef(true);
    const hasListRows = useRef(false);

    useLayoutEffect(() => {
        if (!popupRef.current)
            return;
        const rect = popupRef.current.getBoundingClientRect();
        const parentRect = popupRef.current.parentElement?.getBoundingClientRect();
        const windowHeight = document.getElementsByTagName('html').item(0)?.clientHeight;
        if (parentRect && (parentRect.top - rect.height < 0))
            popupRef.current.className = 'comboBoxPanel lower';
        else if (rect.top + rect.height > (windowHeight || Infinity))
            popupRef.current.className = 'comboBoxPanel upper';
        else
            popupRef.current.className = 'comboBoxPanel lower';
    });

    useEffect(() => {
        const eventClick = (e: MouseEvent) => {
            if (!blockControl.current?.contains((e.target as HTMLElement)))
                closePopup();
        }
        if (isPopupOpen) {
            document.addEventListener('click', eventClick);
            return () => document.removeEventListener('click', eventClick);
        }
    }, [isPopupOpen])

    function getFilters() {
        if (filter)
            return [
                setFilterValue(textColumnFilter('quickfilter_concatenation', 'contains', false), filter)
            ]
        else
            return []
    }
    function useFilter(v: string) {
        if (isAllowFiltering.current) {
            setFilter(v);
            localEventBusRef.current.notify('resetSelect');
        }
    }
    function keyDownInput(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Escape')
            closePopup(true);
        else if (e.key === 'Enter') {
            localEventBusRef.current.notify('onEnterClick');
        } else if (e.key === 'ArrowDown') {
            e.preventDefault();
            localEventBusRef.current.notify('focus', true);
        }
    }
    function keyDownPopup(key: string) {
        if (key === 'Escape')
            closePopup(true);
    }
    function openPopup() {
        setPopupState(true);
        inputRef.current?.select();
    }
    function closePopup(blur?: boolean) {
        setPopupState(false);
        setFilter(null);
        if (blur) inputRef.current?.blur();
        hasListRows.current = false;
    }
    function switchPopupState() {
        isPopupOpen ? closePopup() : openPopup()
    }
    function cleanValue() {
        props.onChange(null, '');
        setPopupState(false);
    }
    function openItem() {
        props.value ? tactin()?.eventBus.notify(new OpenItemEvent().byItemId(props.value)) : createNewItem()
    }
    function createNewItem() {
        if (props.configuration.createNew.category && props.configuration.createNew.type) {
            tactin()?.eventBus.notify(new OpenItemEvent()
                .byType(props.configuration.createNew.type, props.configuration.createNew.category))
        }
    }

    function onSelect(id: number, showAs: string, isClicked?: boolean) {
        if (isClicked) {
            if (id !== props.value)
                props.onChange(id, showAs);
            closePopup(true);
        } else 
           (inputRef.current as HTMLInputElement).value = showAs;
    }

    function getPopupContent() {
        const entitySelector = UIFactory.getEntitySelectorPanel(props.configuration, onSelect, localEventBusRef.current);
        if (!entitySelector?.allowFiltering)
            isAllowFiltering.current = false
        hasListRows.current = true;
        return entitySelector?.control(getFilters()) ?? null;
    }

    const isDeleted = isShowAsDeleted(props.showAs || '');
    const actionDisabled = props.readOnly ? !props.value : (!props.value && !props.configuration?.createNew);

    return (
        <div ref={blockControl} className={props.className || ''}>
            <EntityInput inputRef={(el) => inputRef.current = el}
                className={(props.noClearButton ? 'itemComboBoxTextArea ' : '') + (isDeleted ? 'notAvailableValue' : '')}
                readOnly={props.readOnly}
                filter={filter}
                useFilter={useFilter}
                showAs={props.showAs || ''}
                onKeyDown={keyDownInput}
                openPopup={openPopup} />
            {(props.noClearButton || props.readOnly) ? null : <button type='button' className='itemComboBoxClearButton' onClick={cleanValue}>×</button>}
            <button type='button' disabled={props.readOnly} className='itemComboBoxExpandButton' onClick={switchPopupState}>V</button>
            {props.noActionButton ? null
                : <button type='button' disabled={actionDisabled} className='itemComboBoxActionButton' onClick={openItem}>{`>`}</button>}
            {isPopupOpen ?
                <div ref={popupRef} onKeyDown={(e) => keyDownPopup(e.key)}>
                    <div className='popupContent'>{getPopupContent()}</div>
                </div>
                : null}
        </div>);
}

type EntityInputProps = {
    className: string;
    readOnly?: boolean;
    filter: null | string;
    showAs: string;
    openPopup: () => void;
    useFilter: (v: string) => void;
    inputRef: (el: HTMLInputElement) => void;
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const EntityInput = (props: EntityInputProps) => {
    const [value, setValue] = useState('');
    const timeout = useRef<number>(0);

    function onChange(v: string) {
        setValue(v);
        if (timeout.current)
            clearTimeout(timeout.current);
        timeout.current = window.setTimeout(() => {
            timeout.current = 0;
            props.useFilter(v);
        }, 300)
    }
    function getValue() {
        if (timeout.current)
            return value;
        return props.filter ?? props.showAs;
    }

    return <input type='text'
        className={props.className}
        ref={props.inputRef}
        value={getValue()}
        onChange={(e) => onChange(e.currentTarget.value)}
        disabled={props.readOnly}
        onClick={props.openPopup}
        onKeyDown={props.onKeyDown} />
};
