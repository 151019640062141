import React, { Fragment, useEffect, useState } from 'react';
import { CloseItemEvent, OpenItemEvent, ReplaceItemEvent, ShowMessageEvent } from '../../model/events/TactinEvents';
import { Option } from '../../model/Interface';
import { itemDiff } from '../../model/item/Diff';
import { deepCopy, setNewCategory } from '../../model/item/Item';
import { ClientItem, Item } from '../../model/item/ItemTypes';
import { ActionContext, ActionContextProvider, CategoryActionContext, ItemActionContext } from '../../model/systemactions/ActionContext';
import { HandleErrors } from '../../utils/api/ApiErrorHandler';
import { api } from '../../utils/api/ApiProvider';
import { Failed } from '../../utils/api/TactinApi';
import { tactin } from '../../utils/TactinGlobals';
import { WithWaitNotify } from '../../utils/WaitNotification';
import { useQuestionBox } from '../basic/QuestionBox';
import { Notification } from '../systemactions/SystemAction';
import ItemToolbar, { ToolbarOption } from './ItemToolbar';
import ScrollableMenuList from './ScrollableMenuList';
import SubPanelFactory from './SubPanelFactory';

type CardProps = {
    item: ClientItem;
    isTemplate?: boolean;
    cardId?: string;
    hidden: boolean;
}
export default function Card(props: CardProps) {
    const translate = tactin().configuration.translate;
    const { askQuestion, QuestionControl } = useQuestionBox(translate('Question'));

    const [actualItem, setActualItem] = useState<ClientItem>(() => ({ item: deepCopy(props.item.item), permission: props.item.permission }));
    const [tabState, setTabState] = useState<TabsState>({ options: [], selected: -1, usagePlace: -1 });

    const onChange = (item: Item) => {
        if (item.categoryID !== actualItem.item.categoryID)
            changeCategory(item);
        else
            setActualItem({ item, permission: props.item.permission });
    }

    useEffect(() => {
        if (!props.hidden) {
            const handler = (e: KeyboardEvent) => {
                if (e.ctrlKey && e.key === 's') {
                    e.preventDefault();
                    saveAsync(true).then(([msg, id]) => emitReload(msg, id)).catch(() => { });
                }
            }
            window.addEventListener('keydown', handler);
            return () => window.removeEventListener('keydown', handler);
        }
    }, [props.item, props.hidden, actualItem]);

    const changeCategory = async (item: Item) => {
        api().Item.getCategory(item.categoryID).then(newCategory => {
            const result = setNewCategory(item, newCategory);
            if (result.missingPvs.length > 0 || result.missingElementsCategories.length > 0)
                askQuestion({
                    content: <ChangeCategoryQuestionText missingPvs={result.missingPvs}
                        missingElements={result.missingElementsCategories} />,
                    onYes: () => setActualItem({ item: result.newItem, permission: props.item.permission }),
                    onNo: () => setActualItem({ ...actualItem })
                });
            else
                setActualItem({ item: result.newItem, permission: props.item.permission });
        }).catch(HandleErrors());
    }

    useEffect(() => {
        let newUsagePlace = tactin().configuration.usagePlace(
            actualItem.item.categoryID,
            'category',
            actualItem.item.template || !!props.isTemplate);
        if (!newUsagePlace)
            newUsagePlace = tactin().configuration.usagePlace(
                actualItem.item.type,
                'type',
                actualItem.item.template || !!props.isTemplate);
        setTabState(s => ({ ...s, usagePlace: newUsagePlace }));
    }, [actualItem.item.type, actualItem.item.categoryID, actualItem.item.template]);

    const hasChanges = () => !!itemDiff(props.item.item, actualItem.item, 'save');

    const emitClose = (message: string) =>
        tactin()?.eventBus.notify(new CloseItemEvent(props.cardId, props.item.item.itemID, message));
    const emitReload = (message: string, id: number) =>
        tactin()?.eventBus.notify(new ReplaceItemEvent(message, props.cardId, props.item.item.itemID).byItemId(id));

    const closeAsync = () => {
        if (hasChanges())
            askQuestion({
                content: translate('Some fields have been changed. '
                    + 'Are you sure you want to close the tab without saving changes?'),
                onYes: () => emitClose(''),
                onNo: () => { }
            });
        else
            emitClose('');
    };

    const onToolbarAction = (action: ToolbarOption) => {
        switch (action) {
            case 'delete':
                return deleteAsync().then(msg => emitClose(msg));
            case 'save':
                return saveAsync().then(([msg, id]) => emitReload('', id)).catch(e => {
                    if (e !== null)
                        throw e;
                });
            case 'saveAndClose':
                return saveAsync().then(([msg]) => emitClose('')).catch(e => {
                    if (e === null)
                        emitClose('');
                    else
                        throw e;
                });
        }
    }

    const deleteAsync = () => new Promise<string>((resolve, reject) => {
        if (actualItem.item.itemID <= 0)
            resolve('');
        else {
            api().Item.getRefferingItemNumbers(actualItem.item.itemID)
                .then(refferingItems => {
                    askQuestion({
                        content: <DeleteQuestionText refItems={refferingItems} />,
                        onYes: () => {
                            const diff = itemDiff(props.item.item, { ...actualItem.item, deleted: true }, 'save');
                            if (diff)
                                WithWaitNotify(doSaveItemAsync(diff), translate('Deleting in progress ...'))
                                    .then(() => resolve(''))
                                    .catch(e => reject(e));
                            else
                                resolve('');
                        },
                        onNo: () => reject()
                    });
                })
                .catch(HandleErrors());
        }
    });

    const saveAsync = (quiet?: boolean) => new Promise<[string, number]>((resolve, reject) => {
        const diff = itemDiff(props.item.item, actualItem.item, 'save');
        if (!diff) {
            if (!quiet)
                tactin().eventBus.notify(new ShowMessageEvent(translate('There are no changes to save.')));
            reject(null);
        } else {
            WithWaitNotify(doSaveItemAsync(diff), translate('Saving in progress...'))
                .then(r => resolve(r))
                .catch(e => {
                    if (e instanceof Failed) {
                        tactin()?.eventBus.notify(new ShowMessageEvent(e.message));
                    }
                    reject(e);
                });
        }
    });

    const doSaveItemAsync = (diff: Partial<Item>): Promise<[string, number]> =>
        api().Item.performItemAction(diff)
            .then(sr => {
                const id = actualItem.item.itemID;
                const subId = id <= 0 ? (sr.idSubstitutionMap[actualItem.item.itemID] || 0) : 0;
                if (id <= 0 && subId <= 0)
                    throw new Failed(translate('A new object could not be created.'));
                return [sr.message, subId || id];
            });

    useEffect(() => {
        if (tabState.usagePlace > -1)
            fillMenuOptions(tabState.usagePlace);
    }, [tabState.usagePlace, !!actualItem.item.addOns?.series]);

    const fillMenuOptions = (usagePlace: number) => {

        api().UserInterface.getOptions(usagePlace)
            .then(opts => {
                const builtInOptions: Option[] = [];
                if (actualItem.item.addOns?.series) {
                    if (tactin().configuration.itemSeries.simplifiedInterface()) {
                        builtInOptions.push({
                            name: 'Series settings', icon: 'info_flat', handlerClass: 'simplifiedSeries',
                            id: 0, usagePlace: -1, description: '', handlerConfiguration: {}, order: 0, permissions: []
                        });
                    } else {
                        builtInOptions.push({
                            name: 'Modified fields', icon: 'info_flat', handlerClass: 'modifiedFieldConfig',
                            handlerConfiguration: {}, id: 0, usagePlace: -1, description: '', order: 0, permissions: []
                        }, {
                            name: 'Generation condition', icon: 'info_flat', handlerClass: 'generationConditionConfig',
                            id: 0, usagePlace: -1, description: '', handlerConfiguration: {}, order: 0, permissions: []
                        }, {
                            name: 'Completion condition', icon: 'info_flat', handlerClass: 'realizationConditionConfig',
                            id: 0, usagePlace: -1, description: '', handlerConfiguration: {}, order: 0, permissions: []
                        });
                    }
                }
                setTabState(s => ({
                    ...s,
                    options: [...opts, ...builtInOptions].map(opt => ({
                        option: {
                            ...opt,
                            name: translate(opt.name),
                            description: translate(opt.description)
                        },
                        name: '',
                        panel: null,
                    })),
                    selected: opts.length > 0 ? 0 : -1
                }));
            })
            .catch(HandleErrors());
    }


    const contextProvider: ActionContextProvider = type => {
        if (type === 'item')
            return new ItemActionContext(actualItem);
        else if (type === 'category')
            return new CategoryActionContext(actualItem.item.category);
        else if (type === 'type')
            return new ActionContext('type', actualItem.item.type);
        else
            return null;
    }

    const notifyHandler = (note: Notification) => {
        switch (note.type) {
            case 'message':
                note.data && tactin()?.eventBus.notify(new ShowMessageEvent(String(note.data)));
                break;
            case 'reload':
                setActualItem(a => ({ item: note.data as Item, permission: a.permission }));
                break;
            case 'replace':
                const event = new ReplaceItemEvent('', props.cardId, props.item.item.itemID);
                if (note.data.clientItem)
                    event.byClientItem(note.data.clientItem as ClientItem);
                else if (note.data.item)
                    event.byItem(note.data.item as Item);
                else if (note.data.itemId)
                    event.byItemId(note.data.itemId as number);

                if (event.id || event.clientItem || event.item)
                    tactin()?.eventBus.notify(event);
                break;
            case 'question':
                askQuestion({
                    content: note.data.message,
                    onYes: () => note.data.resume().then((n: Notification | null) => n && notifyHandler(n)).catch(HandleErrors()),
                    onNo: () => note.data.cancel && note.data.cancel().then((n: Notification | null) => n && notifyHandler(n)).catch(HandleErrors())
                });
                break;
            case 'saveBefore':
                const diff = itemDiff(props.item.item, actualItem.item, 'save');
                const afterSave = (item?: Item) => {
                    note.data.resume(item).then((n: Notification | null) => n && notifyHandler(n)).catch(HandleErrors());
                };
                if (diff)
                    askQuestion({
                        content: note.data.message,
                        onYes: async () => {
                            try {
                                const result = await WithWaitNotify(api().Item.performItemAction(diff), translate('Saving in progress...'));
                                const newItem = await api().Item.getItem(result.idSubstitutionMap[actualItem.item.itemID] ?? actualItem.item.itemID);
                                afterSave(newItem.item);
                            } catch (e) {
                                HandleErrors()(e);
                            }
                        },
                        onNo: () => afterSave()
                    });
                else
                    afterSave();
                break;
        }
    }

    const getItemType = () => {
        if (!actualItem.item.template)
            return 'item';
        else if (actualItem.item.addOns?.series)
            return 'series';
        else
            return 'template';
    }

    const selectTab = (i: number) =>
        setTabState(p => ({ ...p, selected: i }));

    const opt = tabState.options[tabState.selected];
    return <div className={`card ${props.hidden ? 'hidden' : ''}`}>
        <Titlebar
            title={translate(props.item.item.showAs || '...')}
            type={getItemType()}
            onClose={() => closeAsync()} />
        <div className='main-card-toolbar'>
            <ItemToolbar onClick={onToolbarAction} />
        </div>
        <div className='tab-card-toolbar'>
            <ScrollableMenuList selected={tabState.selected} tabs={tabState.options.map(o => o.option)} onSelected={selectTab} />
        </div>
        {opt
            ? <SubPanelFactory
                key={tabState.selected}
                option={opt.option}
                original={props.item}
                item={actualItem}
                onChange={onChange}
                className='tab-card-content'
                contextProvider={contextProvider}
                notifyHandler={notifyHandler} />
            : null}
        {QuestionControl}
    </div>
}

type TabsState = {
    options: OptionPanel[];
    selected: number;
    usagePlace: number;
}

type OptionPanel = {
    option: Option;
    name: string;
}

type TitlebarProps = {
    title: string;
    type: 'item' | 'series' | 'template';
    onClose: () => void;
}

function Titlebar(props: TitlebarProps) {
    let className = 'card-title-bar';
    let title = props.title;

    if (props.type === 'series') {
        className += ' series';
        title = `[${tactin().configuration.translate('series').toLocaleUpperCase()}] ${title}`;
    } else if (props.type === 'template') {
        className += ' template';
        title = `[${tactin().configuration.translate('template').toLocaleUpperCase()}] ${title}`;
    }
    return <div className={className}>{title}
        <div className='close button' onClick={props.onClose}>{tactin().configuration.translate('Close')}</div></div>
}

export function MockCard({ item }: { item: Item }) {
    const [counter, setCounter] = useState(0);

    const openNext = () => {
        tactin()?.eventBus.notify(new OpenItemEvent().byItemId(item.itemID + 1));
    }
    const close = () => {
        tactin()?.eventBus.notify(new CloseItemEvent('', item.itemID));
    }

    return <div>
        <div>Card for "{item.showAs}" with item id: {item.itemID}.</div>
        <div>Counter is {counter}. <button onClick={() => setCounter(counter - 1)}>-</button><button onClick={() => setCounter(counter + 1)}>+</button></div>
        <button onClick={openNext}>Open next</button>
        <button onClick={close}>Close</button>
    </div>
}

function DeleteQuestionText({ refItems }: { refItems: string[] }) {
    const translate = tactin().configuration.translate;

    let message = translate('Are you sure you want to delete this item?');
    if (refItems.length > 0)
        message += ` ${translate('It is used by %% other objects.').replace(/%%/, String(refItems.length))}`;
    const refList = refItems.reduce((prev, r) => {
        const parts = r.split('•');
        return `${prev}${prev ? '\n' : ''}${parts.length === 2 ? `[${parts[0]}]: ${parts[1]}` : r}`
    }, '');
    return <div>
        <div>{message}</div>
        <div title={refList} className='questionDialogAdditionalInfo'>
            W celu uzyskania dodatkowych informacji najedź kursorem na ten napis.
        </div>
    </div>;
}


function ChangeCategoryQuestionText({ missingPvs, missingElements }: { missingPvs: string[], missingElements: string[] }) {
    const translate = tactin().configuration.translate;

    const message = [<Fragment key={new Date().getTime().toString()}>{translate('By changing the category, you will lose the following data: ')}</Fragment>];
    missingPvs.forEach(p => message.push(<Fragment key={p}><br /><i>{translate('property')}</i>{p}</Fragment>));
    missingElements.forEach(e => message.push(<Fragment key={e}><br /><i>{translate(`category's elements`)}</i>{e}</Fragment>));
    message.push(<Fragment key={new Date().getMilliseconds().toString()}><br />{translate('Do you want to change the category of the object?')}</Fragment>);

    return <div>{message}</div>;
}
