import React, { useEffect, useRef, useState } from 'react';
import { BarePropertyControlProps } from './PropertyControl';
import DatePicker from 'react-datepicker';
import './react-datepicker.css';
import { WithLabel } from './LabeledControl';
import { DateUtils } from '../../utils/DateUtils';

export function BareDateControl({ value, readOnly, className = '', configuration, onChange }: BarePropertyControlProps) {
    
    const divRef = useRef<HTMLDivElement>(null);
    const datePickerRef = useRef<DatePicker>(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    useEffect(() => {
        const eventClick = (e: MouseEvent) => {
            if (!divRef.current?.contains((e.target as HTMLElement)))
                datePickerRef.current?.setOpen(false);
        }
        if (isPopupOpen) {
            document.addEventListener('click', eventClick);
            return () => document.removeEventListener('click', eventClick);
        }
    }, [isPopupOpen])

    const format = configuration.format ? configuration.format : 'yyyy-MM-dd';
    return (
        <div className={className} ref={divRef}>
            <DatePicker
                ref={datePickerRef}
                selected={value ? new Date(value) : null}
                dateFormat={format}
                strictParsing
                disabled={readOnly}
                className='dataPicker dateTextBox'
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                showWeekNumbers
                dropdownMode='select'
                todayButton='Today'
                onChange={d => {
                    const showAs = d instanceof Date ? DateUtils.formatDate(d, format) : '';
                    onChange(d, showAs);
                }}
                onKeyDown={e => ['Escape', 'Enter'].includes(e.key) && datePickerRef.current?.setBlur()} 
                onCalendarOpen={() => setIsPopupOpen(true)}
                onCalendarClose={() => setIsPopupOpen(false)}/>
        </div>);
}

export const DateControl = WithLabel(BareDateControl);
