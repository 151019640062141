import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { BarePropertyControlProps } from './PropertyControl'
import UIFactory from '../core/UIFactory'
import { setFilterValue, textColumnFilter } from '../../model/list/ColumnFilter'
import { api } from '../../utils/api/ApiProvider'
import { WithLabel } from './LabeledControl'
import './ItemToManyItemControl.css'
import { HandleErrors } from '../../utils/api/ApiErrorHandler'
import LocalEventBus from '../../model/events/LocalEventBus'

type Items = {
    id: number;
    info: string;
    selected: boolean;
}

export function BareItemToManyItemControl({ value, readOnly, className = '', configuration, onChange }: BarePropertyControlProps) {
    const [isPopupOpen, setPopupState] = useState(false);
    const [showAs, setShowAs] = useState<Items[]>([]);
    const toolbarRef = useRef<HTMLDivElement>(null);
    const popupRef = useRef<HTMLDivElement>();
    const selectedItemId = useRef<number>(0);

    useLayoutEffect(() => {
        if (!popupRef.current)
            return;
        const rect = popupRef.current.getBoundingClientRect();
        const parentRect = toolbarRef.current?.getBoundingClientRect();
        const window = document.getElementsByTagName('html').item(0);
        if (window?.clientWidth && parentRect) {
            const canFitRight = window?.clientWidth > (parentRect.right + rect.width);
            const canFitUnderneath = rect.top + rect.height < (window?.clientHeight || Infinity);
            const setPosition = (nameClass: string) => {
                const popupElem = popupRef.current as HTMLDivElement;
                popupElem.classList.add(nameClass);
                if (parentRect.width < rect.width && !canFitRight)
                    popupElem.classList.add('widthByInpyt');
            }
            if (canFitRight && !canFitUnderneath)
                setPosition('popupRightUpper');
            else if (!canFitRight && canFitUnderneath)
                setPosition('popupLower');
            else if (!canFitRight && !canFitUnderneath)
                setPosition('popupUpper');
            else
                setPosition('popupRight');
        }
    })

    const getShowAsList = async (ids: number[]): Promise<Items[]> => {
        let strs: string[];
        try {
            strs = await api().Item.getItemsShowAs(value);
        } catch (e) {
            strs = ids.map(id => `ID:${id}`);
            HandleErrors()(e);
        }
        return strs.map((showAs, i) => ({ id: value[i], info: showAs, selected: false }));
    }

    const isComponentExist = () => !!toolbarRef.current;

    useEffect(() => {
        if (value)
            getShowAsList(value).then(items => isComponentExist() && setShowAs(items));
        else
            setShowAs([]);
        selectedItemId.current = 0;
    }, [value])

    useEffect(() => {
        const eventClick = (e: MouseEvent) => {
            if (!popupRef.current?.contains((e.target as HTMLElement)))
                closePopup();
        }
        if (isPopupOpen) {
            document.addEventListener('click', eventClick);
            return () => document.removeEventListener('click', eventClick);
        }
    }, [isPopupOpen])

    function openPopup() { setPopupState(true) }
    function closePopup() { setPopupState(false) }
    function selectListItem(v: string) {
        const newItemsList = showAs.map(item => {
            if (item.id === Number(v)) {
                item.selected = true;
                selectedItemId.current = item.id;
                console.log(`selected item id: ${item.id}`);
            } else
                item.selected = false;
            return item;
        })
        setShowAs(newItemsList);
    }
    function deleteItem() {
        if (selectedItemId.current) {
            const newValue = value.filter((i: number) => i !== selectedItemId.current)
            onChange(newValue);
            selectedItemId.current = 0;
        }
    }
    function getItemsList() {
        if (showAs.length) {
            return showAs.map(item =>
                <div key={item.id}
                    id={item.id.toString()}
                    className={item.selected ? 'i2miControlChosenItem' : 'i2miControlItem'}
                    onClick={(e) => selectListItem(e.currentTarget.id)}>{item.info}</div>)
        } else
            return null;
    }

    return <div className={`i2miControl ${className}`} >
        <div className={`i2miToolbar ${readOnly ? 'disabledControl' : ''}`} ref={toolbarRef}>
            <div className='i2miToolbarLabel'>Item</div>
            <div className='i2miToolbarButtonsBlock'>
                <button type='button' className='i2miAddButton' onClick={openPopup}>+</button>
                <button type='button' className='i2miRemoveButton' onClick={deleteItem}>-</button>
            </div>
            {isPopupOpen && <Popup popupRef={(el) => { popupRef.current = el }} value={value} onChange={onChange}
                closePopup={closePopup} configuration={configuration} />}
        </div>
        {showAs.length > 0
            ? <div className={`controlList ${readOnly ? 'disabledControl' : ''}`}>{getItemsList()} </div>
            : <div className='controlPsevdoList'></div>
        }
    </div>
}

type PopupProps = {
    value: number[];
    configuration: { handlerClass: string, dataProvider: string };
    onChange: (value: number[]) => void;
    popupRef: (el: HTMLInputElement) => void;
    closePopup: () => void;
}

function Popup(props: PopupProps) {
    const [filter, setFilter] = useState<null | string>(null);
    const [isAllowFiltering, setAllowFiltering] = useState(true);
    const localEventBusRef = useRef(new LocalEventBus());

    function getFilters() {
        if (filter)
            return [
                setFilterValue(textColumnFilter('quickfilter_concatenation', 'contains', false), filter)
            ]
        else
            return []
    }

    function useFilter(v: string) {
        setFilter(v);
        localEventBusRef.current.notify('resetSelect');
    }

    function onSelect(id: number, showAs: string, isClicked?: boolean) {
        if (isClicked) {
            props.closePopup();
            let isContainId = false;
            props.value.forEach((item: number) => {
                if (item === id)
                    isContainId = true;
            })
            if (!isContainId) {
                const newValue = [...props.value];
                newValue.push(id);
                props.onChange(newValue);
            }
        }
    }

    function keyDownInput(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            localEventBusRef.current.notify('focus', true);
        }
    }

    function getPopupContent() {
        const entitySelector = UIFactory.getEntitySelectorPanel(props.configuration, onSelect, localEventBusRef.current);
        if (!entitySelector?.allowFiltering)
            setAllowFiltering(false);
        return entitySelector?.control(getFilters());
    }

    return (
        <div ref={props.popupRef} className='Item2miPopup'>
            {isAllowFiltering && <Input useFilter={useFilter} onKeyDown={keyDownInput} />}
            <div className='popup-content height'>{getPopupContent()}</div>
        </div>
    )
}

type InputProps = {
    useFilter: (v: string) => void;
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

function Input(props: InputProps) {
    const [value, setValue] = useState('');
    const timeout = useRef<number>(0);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        inputRef.current && inputRef.current.focus();
    }, [])

    function onChange(v: string) {
        setValue(v);
        if (timeout.current)
            clearTimeout(timeout.current);
        timeout.current = window.setTimeout(() => {
            timeout.current = 0;
            props.useFilter(v);
        }, 300)
    }

    return <input
        ref={inputRef}
        type='text'
        placeholder='filter'
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
        onKeyDown={props.onKeyDown} />
}

export const ItemToManyItemControl = WithLabel(BareItemToManyItemControl);
