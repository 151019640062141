import React, { useEffect, useState } from 'react';
import { TactinEvent, WaitEvent } from '../../model/events/TactinEvents';
import { tactin } from '../../utils/TactinGlobals'

export default function WaitNotifier() {
    useEffect(() => {
        return tactin()?.eventBus.register(messageHandler);
    }, []);

    const messageHandler = (event: TactinEvent) => {
        if (event instanceof WaitEvent) {
            if (event.finished)
                removeProcess(event.id);
            else
                updateProcess(event.id, event.message);
        }
    }

    const updateProcess = (id: number, message: string) => {
        const existing = processes.find(p => p.id === id);
        if (existing)
            setProcesses(p => p.map(proc => proc.id === id ? { id, message } : proc));
        else
            setProcesses(p => [...p, { id, message }]);
    }

    const removeProcess = (id: number) =>
        setProcesses(p => p.filter(proc => proc.id !== id));

    const [processes, setProcesses] = useState<WaitProcess[]>([]);

    const messagesTitle = processes.map(p => p.message).join('\n');

    if (processes.length)
        return (
            <div className='frameless-popup'>
                <div className='background'></div>
                <div className='message-box'>
                    {processes.length === 1
                        ? <div>{processes[0].message}</div>
                        : <div title={messagesTitle}>Waiting for {processes.length} processes to finish...</div>}
                </div>
            </div>);
    else
        return null;
}

type WaitProcess = {
    id: number;
    message: string;
}
