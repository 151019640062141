import React from 'react';
import { Item } from '../../model/item/ItemTypes';
import { ActionContextProvider } from '../../model/systemactions/ActionContext';
import { CreateNewItemSystemAction } from '../../model/systemactions/CreateSystemActions';
import { CallSystemCommandSystemAction, ChangeUserPasswordSystemAction, ExportToExcelSystemAction, LogoutSystemAction } from '../../model/systemactions/OtherActions';
import { CreateSeriesSystemAction, RestoreSeriesSystemAction } from '../../model/systemactions/SeriesSystemActions';
import { CreateItemFromTemplateSystemAction, CreateTemplateSystemAction, IndividualizeItemSystemAction, RestoreSharedItemSystemAction } from '../../model/systemactions/TemplateSystemActions';

export type SystemActionExecutor = () => Promise<Notification | null>;
export type Notification = {
    type: string;
    data: any;
}

export function MessageNotification(message: string): Notification {
    return {
        type: 'message',
        data: message
    }
}
export function QuestionNotification(message: string, resume: () => Promise<Notification | null>, cancel?: () => Promise<Notification | null>) {
    return {
        type: 'question',
        data: {
            message,
            resume,
            cancel
        }
    }
}
export function SaveBeforeNotification(message: string, resume: (item?: Item) => Promise<Notification | null>, cancel?: () => Promise<Notification | null>) {
    return {
        type: 'saveBefore',
        data: {
            message,
            resume,
            cancel
        }
    }
}

type SystemActrionProps = {
    name: string;
    configuration: any;
    contextProvider?: ActionContextProvider;
    children: (execute: SystemActionExecutor) => JSX.Element;
}
export function SystemActrion(props: SystemActrionProps) {
    const executor = getExecutor(props.name, props.configuration, props.contextProvider);

    return (
        <>{props.children(executor)}</>
    );
}

function getExecutor(name: string, configuration: any, contextProvider?: ActionContextProvider): SystemActionExecutor {
    switch (name) {
        case 'LogoutSystemAction':
            return LogoutSystemAction();
        case 'ExportToExcelSystemAction':
            return ExportToExcelSystemAction(contextProvider);
        case 'CreateNewItemSystemAction':
            return CreateNewItemSystemAction(configuration, contextProvider);
        case "CreateTemplateSystemAction":
            if (contextProvider)
                return CreateTemplateSystemAction(contextProvider);
        case "CreateItemFromTemplateSystemAction":
            if (contextProvider)
                return CreateItemFromTemplateSystemAction(configuration, contextProvider);
        case 'IndividualizeItemSystemAction':
            if (contextProvider)
                return IndividualizeItemSystemAction(contextProvider, configuration?.categoryId);
        case 'RestoreSharedItemSystemAction':
            if (contextProvider)
                return RestoreSharedItemSystemAction(contextProvider, configuration?.categoryId);
        case 'CreateSeriesSystemAction':
            if (contextProvider)
                return CreateSeriesSystemAction(contextProvider);
        case 'RestoreSeriesSystemAction':
            if (contextProvider)
                return RestoreSeriesSystemAction(contextProvider);
        case 'ChangeUserPasswordSystemAction':
            return ChangeUserPasswordSystemAction();
        case 'CallSystemCommandSystemAction':
            return CallSystemCommandSystemAction(configuration, contextProvider);
        default:
            return async () => null;
    }
}
