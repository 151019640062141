import React, { ReactElement } from 'react';
import { RowData } from '../../../model/list/cache/RowParser';
import { TableCellProps } from './TableCell';

export type TableRowProps<T> = {
    columns: string[];
    widths: string[];
    groups?: string[];
    className?: string;
    id?: number;
    data?: RowData;
    isSelectedRow?: boolean;
    isHighlighted?: boolean;
} & T;

type RowProps = {
    children: (props: TableCellProps<{ i: number }>) => ReactElement;
    onClick?: () => void;
    onDblClick?: () => void;
}

export default function TableRow(props: TableRowProps<RowProps>) {
    const groupRow = (props.data && (props.data.subCount ?? 0) > 0 && !props.data?.id) ?? false;
    const classes = ['tr'];
    if (props.className) classes.push(props.className);
    if (groupRow) classes.push('group-row');
    if (props.isSelectedRow) classes.push('selected');

    return (
        <div className={classes.join(' ')}
            onClick={props.onClick}
            onDoubleClick={props.onDblClick}>
            {props.columns.map((c, i) =>
                props.children({
                    i, width: props.widths[i] || `${Math.floor(100 / props.columns.length)}%`,
                    canGrow: !props.widths[i]
                }))}
        </div>
    );
}
