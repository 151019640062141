import React from 'react';
import { RowData } from '../../../model/list/cache/RowParser';
import ListCell from './ListCell';
import TableRow, { TableRowProps } from './TableRow';

type ListRowProps = {
    data: RowData;
    isSelectedRow?: boolean;

    onFoldToggle?: (meta: string) => void;
    onMouseOver?: (fullText: string) => void;
    onMouseOut?: (fullText: string) => void;

    onClick: () => void;
    onDblClick: () => void;
}

export default function ListRow(props: TableRowProps<ListRowProps>) {
    return <TableRow columns={props.columns} widths={props.widths} onClick={props.onClick} onDblClick={props.onDblClick}
        isSelectedRow={props.isSelectedRow} data={props.data}>
        {({ i, width, canGrow }) =>
            <ListCell key={`col${i}`} width={width} canGrow={canGrow}
                data={props.data.columns[i] || ''}
                subCount={props.data.subCount}
                groupColumn={i < (props.groups?.length ?? 0)}
                onFold={props.onFoldToggle}
                onMouseOver={props.onMouseOver}
                onMouseOut={props.onMouseOut} />}
    </TableRow>
}
