import React, { Fragment, useEffect, useState } from 'react';
import { ShowMessageEvent, TactinEvent } from '../../model/events/TactinEvents';
import { tactin } from '../../utils/TactinGlobals'

export default function TactinMessage() {
    useEffect(() => {
        return tactin()?.eventBus.register(messageHandler);
    }, []);

    const messageHandler = (event: TactinEvent) => {
        if(event instanceof ShowMessageEvent)
            setMessage(event.message);
    }
    const [message, setMessage] = useState('');

    if(message)
        return (
        <div className='frameless-popup'>
            <div className='background' onClick={() => setMessage('')}></div>
            <div className = 'message-box'>
                {message.split('\n').flatMap((l, i) => [<Fragment key={i+l}>{l}</Fragment>, <br/>])}
                <em>(Kliknij obszar na zewnątrz ramki, aby ją zamknąć.)</em>
            </div>
        </div>);
    else
        return null;
}
