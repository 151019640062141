import React from 'react';
import { WithLabel } from './LabeledControl';
import { BarePropertyControlProps } from './PropertyControl'

export function BareBitControl({ value, readOnly, className = '', onChange }: BarePropertyControlProps) {
    return (
        <div className={className}>
            <input
                type='checkbox'
                checked={value ?? false}
                disabled={readOnly}
                onChange={() => { onChange(!value) }}
                onKeyDown={e => ['Escape', 'Enter'].includes(e.key) && e.currentTarget.blur()}
            />
        </div>);
}

export const BitControl = WithLabel(BareBitControl);
