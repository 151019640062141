import React, { useState, useEffect, useRef } from 'react';
import { BarePropertyControlProps } from './PropertyControl';
import { DownloadFileEvent } from '../../model/events/TactinEvents';
import { tactin } from '../../utils/TactinGlobals';
import { api } from '../../utils/api/ApiProvider';
import { WithLabel } from './LabeledControl';
import { HandleErrors, HandleFailed } from '../../utils/api/ApiErrorHandler';
import MessageBox from '../basic/MessageBox';
import './LinkControl.css'
import { downloadFileURL } from '../../utils/api/FileRepositoryApi';
import { useQuestionBox } from '../basic/QuestionBox';
import { useExistenceCheck } from '../../utils/EntityStore';

const imgExt = '.png,.jpg,.jpeg,.gif,.webp,.bmp,.svg,.ico,.avif,.apng';

type BareLinkControlProps = {
    onlyImgFiles?: boolean;
    currentImg?: string;
    setCurrentImg?: (v: string) => void;
} & BarePropertyControlProps

export function BareLinkControl({ className = '', value, onChange, onlyImgFiles = false, currentImg, setCurrentImg }: BareLinkControlProps) {
    const configMaxSize = tactin().configuration.systemInfo('maxFileUploadSize');

    const MAX_FILE_SIZE = parseMaxFileSize(configMaxSize);
    const [fileName, setFileName] = useState('');
    const [uploadProgress, setUploadProgress] = useState(-1);
    const [onlyImgMessage, setOnlyImgMessage] = useState(false);
    const { askQuestion, QuestionControl } = useQuestionBox('');
    const { checkExistence, setUnexist } = useExistenceCheck();

    const doAbort = useRef<() => void>();

    const trimName = (fileName: string) =>
        fileName.length > 25 ? `${fileName.slice(0, 15)} ... ${fileName.slice(fileName.length - 15)}` : fileName;

    useEffect(() => setUploadProgress(-1), [value]);

    useEffect(() => {
        if (!value)
            setFileName('');
    }, [value]);

    useEffect(() => {
        if (onlyImgFiles && value && setCurrentImg) {
            api().FileRepository.getFile(value)
                .then(res => checkExistence() && setCurrentImg(downloadFileURL(res)))
                .catch(e => HandleErrors())
        }
        return () => { setUnexist() }
    }, [])

    function getFileName(id: number): void {
        api().FileRepository.getFileName(id)
            .then(result => {
                setFileName(result)
            })
            .catch(HandleErrors(true));
    }

    function uploadFile(file: File, fileName: string) {
        if (onlyImgFiles) {
            const ext = file.type.split('/').pop();
            if (ext) {
                const isValid = imgExt.indexOf(ext);
                if (isValid === -1) {
                    setOnlyImgMessage(true);
                    return;
                }
            }
        }

        if (file.size > MAX_FILE_SIZE) {
            alert(`File exceeds the maximum size (${configMaxSize}B).`);
            return;
        }

        api().FileRepository.uploadFile('UploadFileHandler', file, setUploadProgress, fn => doAbort.current = fn)
            .then(result => {
                const id = result.fileIds.get(fileName);
                onChange(id);
                if (setCurrentImg) setCurrentImg(URL.createObjectURL(file));
            })
            .catch(HandleFailed(() => {
                setFileName('');
                onChange(null);
            }));

        setFileName(fileName);
    }

    function abortUploader() {
        if (doAbort.current) doAbort.current();
        setFileName('');
        setUploadProgress(-1);
    }

    function downloadFile() {
        if (value && value > 0)
            tactin()?.eventBus.notify(new DownloadFileEvent(value));
    }

    function deleteFile() {
        askQuestion({
            content: tactin().configuration.translate(`Are you sure you want to delete the ${onlyImgFiles ? 'image' : 'file'}?`),
            onYes: () => {
                onChange(null);
                if (setCurrentImg) setCurrentImg('');
            }
        })
    }

    const uploadBlock = () => <>
        <FileUploadButton onFileSelected={file => uploadFile(file, file.name)} onlyImgFiles={onlyImgFiles} />
        <DropArea onUpload={file => uploadFile(file, file.name)} onlyImgFiles={onlyImgFiles} />
    </>

    const progressBlock = () => <>
        <div className='fileUploadLinkAnchor'>{trimName(fileName)}{uploadProgress >= 0 ? ` (${uploadProgress}%)` : ''}</div>
        <button type='button' className='fileUploadCancelButton' onClick={abortUploader}>Cancel</button>
    </>

    const fileBlock = () => <>
        {onlyImgFiles ? imgBlock() : linkBlock()}
        {QuestionControl}
    </>

    const imgBlock = () =>
        currentImg ?
            <div className='img-block'>
                <img src={currentImg} alt='' />
                <div className='img-clear' onClick={deleteFile}>X</div>
            </div>
            : <div>...loading</div>

    const linkBlock = () => <>
        <div className='fileUploadLinkAnchor' onClick={downloadFile}>{trimName(fileName)}</div>
        <button type='button' className='fileUploadDeleteButton' onClick={deleteFile}>Clear</button>
    </>

    useEffect(() => {
        if (fileName)
            return;

        if (value) {
            setFileName('...');
            getFileName(value);
        }
    }, [value])

    let block;
    if (!fileName)
        block = uploadBlock();
    else if (!value)
        block = progressBlock();
    else
        block = fileBlock();

    return <div className={className}>
        {block}
        {onlyImgMessage && <MessageBox
            title='Invalid file type'
            message='Unable to load data file type. The file must name an extension like Image. Please choose another file.'
            onClose={() => setOnlyImgMessage(false)}
        />}
    </div>
}

function parseMaxFileSize(value: string): number {
    if (!value)
        return Infinity;

    if (!"KMG".includes(value.slice(value.length - 1)))
        return Number(value.trim());

    let power = 0;
    const ending = value.slice(value.length - 1);
    switch (ending) {
        case "K": power = 1; break;
        case "M": power = 2; break;
        case "G": power = 3; break;
        default:
            break;
    }
    return Number(value.slice(0, value.length - 1).trim()) * 1024 ** power;
}

type FileUploadButtonProps = {
    onFileSelected: (e: File) => void;
    onlyImgFiles: boolean;
}

function FileUploadButton({ onFileSelected, onlyImgFiles }: FileUploadButtonProps) {
    const inputLoader = useRef<HTMLInputElement>(null);
    return (
        <div className='fileUploaderControl'>
            <input type='file' className='inputLoader' ref={inputLoader}
                accept={!onlyImgFiles ? '*' : imgExt}
                onChange={e => {
                    if (!e.currentTarget.files?.length) return;
                    onFileSelected(e.currentTarget.files[0]);
                }} />
            <span className='fileUploaderButton' onClick={() => inputLoader.current?.click()}>Load...</span>
        </div>);
}

type DropAreaProps = {
    onUpload: (file: File) => void;
    onlyImgFiles: boolean;
}

function DropArea({ onUpload, onlyImgFiles }: DropAreaProps) {
    const [hoverClass, setHoverClass] = useState(false);

    const baseHandleEvent = (e: React.DragEvent, setHover: boolean): void => {
        e.preventDefault();
        e.stopPropagation();
        setHoverClass(setHover);
    };

    return <div className={`dropFileUploader ${hoverClass ? 'dropFileUploadHover' : ''}`}
        onDragEnter={e => baseHandleEvent(e, true)}
        onDragLeave={e => baseHandleEvent(e, false)}
        onDragOver={e => baseHandleEvent(e, true)}
        onDrop={e => {
            baseHandleEvent(e, false);
            const files = e.dataTransfer.files;
            if (files) {
                if (files.length > 1)
                    alert('You cannot upload multiple files at once.');
                else
                    onUpload(files[0]);
            }
        }}
    >{`...or drop the ${onlyImgFiles ? 'image' : 'file'} here`}</div>;
}

export const LinkControl = WithLabel(BareLinkControl);
