import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { tactin } from '../../utils/TactinGlobals';
import { WithLabel } from './LabeledControl';
import { BarePropertyControlProps } from './PropertyControl'

type ValueItemType = {
    value: number;
    text: string;
    ordering?: number;
}

export function BareValueListControl({ value, className = '', configuration, onChange, readOnly }: BarePropertyControlProps) {

    const [values, setValues] = useState<{ all: ValueItemType[], available: ValueItemType[] }>({ all: [], available: [] });
    const [selectedOption, selectOption] = useState(-1);
    const [isPopupOpen, setPopupState] = useState(false);
    const popupRef = useRef<HTMLDivElement>(null);
    const optionHeight = 25;
    const maxOptionsNum = 6;

    useEffect(() => {
        if (configuration?.values) {
            const allValues: ValueItemType[] =
                (configuration.values as string[]).map((s, i) => ({ value: i + 1, text: s, ordering: getOrderin(s) }));
            // filtering
            let newValues: ValueItemType[];
            if (configuration.shown)
                newValues = allValues.filter(valueItem => (configuration.shown as number[]).includes(valueItem.value))
            else if (configuration.hidden?.length)
                newValues = allValues.filter(valueItem => !(configuration.hidden as number[]).includes(valueItem.value));
            else
                newValues = [...allValues];
            // sorting
            newValues = newValues.sort((a, b) => {
                if (a.ordering !== undefined && b.ordering !== undefined)
                    return a.ordering - b.ordering;
                else if (a.ordering !== undefined)
                    return -1;
                else if (b.ordering !== undefined)
                    return 1;
                else
                    return a.text.toLocaleLowerCase().localeCompare(b.text.toLocaleLowerCase());
            })
            newValues.unshift({ value: 0, text: tactin().configuration.translate('<brak wyboru>'), ordering: undefined })
            setValues({
                all: allValues,
                available: newValues
            })
        }
    }, [configuration]);

    useLayoutEffect(() => {
        if (popupRef.current) {
            definePopupPosition();
            definePopupHeight();
        }
    });

    useEffect(() => {
        if (isPopupOpen) {
            const handler = (e: MouseEvent) => {
                if (!popupRef.current?.contains((e.target as HTMLElement)))
                    setPopupState(false);
            }
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    }, [isPopupOpen])

    useLayoutEffect(() => {
        if (selectedOption >= 0) 
            focusOnRow();
    }, [selectedOption]);

    const focusOnRow = () => {
        if (popupRef.current) {
            const popupBody = popupRef.current.firstElementChild as HTMLDivElement;
            const popupRect = popupBody.getBoundingClientRect();
            const popupTop = popupRect.top;
            const popupBottom = popupRect.bottom;
            const optionRect = popupBody.children[selectedOption].getBoundingClientRect();
            const optionTop = optionRect.top;
            const optionBottom = optionRect.bottom;
            if (optionBottom > popupBottom)
                popupBody.scrollTo(0, (selectedOption * optionHeight));
            if (optionTop < popupTop)
                popupBody.scrollTo(0, ((selectedOption - maxOptionsNum + 1) * optionHeight));
        }
    }

    const definePopupPosition = () => {
        const popup = popupRef.current as HTMLDivElement;
        const rect = popup.getBoundingClientRect();
        const parentRect = popup.parentElement?.getBoundingClientRect();
        const windowHeight = document.getElementsByTagName('html').item(0)?.clientHeight;
        if (parentRect && (parentRect.top - rect.height < 0))
            popup.className = 'comboBoxPanel lower';
        else if (rect.top + rect.height > (windowHeight || Infinity))
            popup.className = 'comboBoxPanel upper';
        else
            popup.className = 'comboBoxPanel lower';
    }

    const definePopupHeight = () => {
        const popup = popupRef.current as HTMLDivElement;
        const valuesNumber = values.available.length;
        const visibleOptionNum = valuesNumber > maxOptionsNum ? maxOptionsNum : valuesNumber;
        popup.style.height = (optionHeight * visibleOptionNum).toString() + 'px';
    }

    const closePopup = () => {
        setPopupState(false);
        selectOption(-1);
    }

    const setValue = (v: number, s: string) => {
        if (v !== value)
            onChange(v || null, s);
        closePopup();
    }

    const additionalClasses = () => {
        if (value) {
            if (!values.all.find(a => a.value === value))
                return 'notExistValue';
            if (!values.available.find(a => a.value === value))
                return 'notAvailableValue';
        }
    }

    const getCurrentText = () =>
        !value ? '' : values.all.find(a => a.value === value)?.text || `Missing ID: ${value}`;

    const inputKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        e.preventDefault();
        if (e.key === 'Enter') {
            if (selectedOption >= 0) {
                const currentOption = values.available[selectedOption];
                setValue(currentOption.value, currentOption.text);
            } else
                isPopupOpen ? closePopup() : setPopupState(true);
        }
        if (e.key === 'Escape')
            closePopup();
        if (!isPopupOpen)
            return
        if (e.key === 'ArrowDown')
            if (selectedOption < 0)
                selectOption(0);
            else if ((selectedOption + 1) < values.available.length)
                selectOption(selectedOption + 1);
        if (e.key === 'ArrowUp') {
            if (selectedOption > 0)
                selectOption(selectedOption - 1);
        }
    }

    return (<div className={className} >
        <input 
            type='text'
            readOnly
            disabled={readOnly}
            value={getCurrentText()}
            className={`itemComboBoxTextArea ${additionalClasses()}`}
            onClick={() => !readOnly && setPopupState(true)}
            onKeyDown={inputKeyDown} />
        <button 
            type='button'
            className='itemComboBoxExpandButton'
            disabled={readOnly}
            onClick={() => setPopupState(true)}
            onKeyDown={inputKeyDown}
        >V</button>
        {isPopupOpen &&
            <div ref={popupRef}>
                <div className='comboBoxListArea'>
                    {values.available.map((item, ind) =>
                        <div
                            key={item.value}
                            className={`comboBoxListArea_option ${selectedOption === ind ? 'selected' : ''}`}
                            onClick={() => setValue(item.value, item.text)}
                        >{item.text}</div>)}
                </div>
            </div>}
    </div>);
}

export const ValueListControl = WithLabel(BareValueListControl);

function getOrderin(text: string) {
    const match = text.match(/^\d+/);
    if (match)
        return Number(match[0]);
}
