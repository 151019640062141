import { FileLink } from "../../model/ConfigurationEntities";
import { TFileRepositoryApi } from "./ApiTypes";
import { toBooleanResult, toFileTokenResult, toFileUploadResult, toRawResult, toStringResult } from "./Results";
import * as Api from "./TactinApi";

const MODULE = "FileModule";

function toFileList(result: Api.ServerActionResult): Promise<FileLink[]> {
    return new Promise((resolve, reject) => {
        const rawResult = toRawResult(result);
        if (!rawResult)
            reject(new Api.ExecutionError("Wrong response from server!"));
        else
            resolve((rawResult.values as FileLink[]) || []);
    });
}

export function downloadFileURL(token: string): string {
    return "tactin/api?module=FileDownloadModule&json="
        + encodeURIComponent(JSON.stringify({ fileToken: token }));
}

export const FileRepositoryApi = (token: string): TFileRepositoryApi => ({
    getFileName: (id: number) =>
        Api.sendRequest(MODULE, "getFileName", { id }, token).then(r => toStringResult(r)),
    deleteFile: (id: number) =>
        Api.sendRequest(MODULE, "deleteFile", { id }, token).then(r => toBooleanResult(r)),
    excelFileDownload: (params: {
        dataProviderId: string,
        sortColumn: string,
        sortDirection: string,
        filters: any[],
        wildcardValues: string[]
    }) =>
        Api.sendRequest(MODULE, "excelFileDownload", params, token).then(r => toFileTokenResult(r)),
    getFile: (id: number) =>
        Api.sendRequest(MODULE, "getFile", { id }, token).then(r => toFileTokenResult(r)),
    getReport: (params: {
        definitionId: number,
        dataProviderId: string,
        start: number,
        length: number,
        sortColumn: string,
        sortDirection: string,
        name: string,
        number: string,
        parentId: number | null
    }) =>
        Api.sendRequest(MODULE, "getReport", params, token).then(r => toFileTokenResult(r)),
    getFiles: () =>
        Api.sendRequest(MODULE, "getFiles", {}, token).then(r => toFileList(r)),
    uploadFile: (fileHandlerClass: string, data: File, showProgress?: (progress: number) => void, getAbort?: (fn: () => void) => void) =>
        Api.uploadFile(MODULE, "uploadFile", { fileHandlerClass }, data, token, showProgress, getAbort).then(r => toFileUploadResult(r)),
});
