import React, { PropsWithChildren } from 'react';

export type TableCellProps<T> = {
    width: string;
    canGrow?: boolean;
    className?: string;
} & T;

type CellEventHandler = {
    onClick?: (event: any) => void;
    onMouseOver?: (event: any) => void;
    onMouseOut?: (event: any) => void;
}
export default function TableCell(props: PropsWithChildren<TableCellProps<CellEventHandler>>) {
    const classes = ['td'];
    if (props.className)
        classes.push(props.className);
    return (<div className={classes.join(' ')} style={{ flex: `${props.canGrow ? 1 : 0} 1 ${props.width}` }}
        onClick={props.onClick}
        onMouseOver={props.onMouseOver}
        onMouseOut={props.onMouseOut}>
        {props.children}
    </div>);
}
